import { useCallback } from 'react';
import { FieldValidationRules } from '@wilm/common';

const useValidate = () => {
    const validateTextExists = useCallback((name: string) => name?.length >= 1, []);

    const validateEmail = useCallback((email: string) => {
        console.info('---> email', email);

        const emailRules = FieldValidationRules.EMAIL.REGEX;
        return emailRules.test(email);
    }, []);

    return {
        validateEmail,
        validateTextExists
    };
};

export default useValidate;
